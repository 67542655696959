import React from 'react'
import { CallMe } from '@components'
import './styles.less'
import { Route, Switch } from 'react-router-dom'
import routes from '@utils/routes'

const App = () => (
    <>
        <CallMe />
        <React.Suspense fallback={<div />}>
            <Switch>
                {routes.map(route => (
                    <Route key={route.name} {...route} />
                ))}
            </Switch>
        </React.Suspense>
    </>
)

export default App
