import { spline } from '@georgedoescode/spline'
import SimplexNoise from 'simplex-noise'
import React from 'react'
import './styles.less'

const Mask = () => {
    const [top, setTop] = React.useState(0)
    const [height, setHeight] = React.useState(0)
    React.useEffect(() => {
        setHeight(
            document.body.scrollHeight -
                document.body.getBoundingClientRect().height
        )
    })

    React.useEffect(() => {
        const func = () => {
            setTop(-document.body.getBoundingClientRect().y)
        }
        func()
        window.addEventListener('scroll', func)
        return () => window.removeEventListener('scroll', func)
    })

    React.useEffect(() => {
        const path = document.getElementById('blob-mask')

        const noiseStep = 0.01

        const simplex = new SimplexNoise()

        function map(n, start1, end1, start2, end2) {
            return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
        }

        function noise(x, y) {
            return simplex.noise2D(x, y)
        }

        function createPoints() {
            // eslint-disable-next-line no-shadow
            const points = []
            // how many points do we need
            const numPoints = 5
            // used to equally space each point around the circle
            const angleStep = (Math.PI * 2) / numPoints
            // the radius of the circle
            const rad = 200

            for (let i = 1; i <= numPoints; i += 1) {
                // x & y coordinates of the current point
                const theta = i * angleStep

                const x = 100 + Math.cos(theta) * rad
                const y = 100 + Math.sin(theta) * rad

                // store the point's position
                points.push({
                    x,
                    y,
                    // we need to keep a reference to the point's original point for when we modulate the values later
                    originX: x,
                    originY: y,
                    // more on this in a moment!
                    noiseOffsetX: Math.random() * 1000,
                    noiseOffsetY: Math.random() * 1000
                })
            }

            return points
        }

        const points = createPoints()

        ;(function animate() {
            path.setAttribute('d', spline(points, 1, true))

            // for every point...
            for (let i = 0; i < points.length; i += 1) {
                const point = points[i]

                // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                const nX = noise(point.noiseOffsetX, point.noiseOffsetX)
                const nY = noise(point.noiseOffsetY, point.noiseOffsetY)
                // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                const x = map(nX, -1, 1, point.originX - 20, point.originX + 20)
                const y = map(nY, -1, 1, point.originY - 5, point.originY + 5)

                // update the point's current coordinates
                point.x = x
                point.y = y

                // progress the point's x, y values through "time"
                point.noiseOffsetX += noiseStep
                point.noiseOffsetY += noiseStep
            }

            requestAnimationFrame(animate)
        })()
    }, [])
    return (
        <mask id="mask">
            <g fill="white">
                <path
                    id="blob-mask"
                    d=""
                    fillOpacity={0.6}
                    transform={`translate(0,${
                        -100 - ((600 - 100) / (height || 1)) * top
                    })`}
                />
            </g>
        </mask>
    )
}

export default Mask
