import React from 'react'
import { Grid } from 'antd'
import { WhatsAppOutlined } from '@ant-design/icons'
import { green } from '@ant-design/colors'

const CallMe = () => {
    const screens = Grid.useBreakpoint()
    const [spin, setSpin] = React.useState(false)
    React.useEffect(() => {
        setTimeout(() => setSpin(prev => !prev), spin ? 2000 : 10000)
    }, [spin])

    return (
        <a
            style={{
                position: 'fixed',
                bottom: 24,
                right: 24,
                zIndex: 10000,
                borderRadius: '50%',
                background: green[5],
                padding:
                    // eslint-disable-next-line no-nested-ternary
                    screens.xxl || screens.xl ? (spin ? 16 : 8) : spin ? 32 : 16
            }}
            href="https://wa.me/79161144932"
            target="_blank"
            rel="noreferrer"
        >
            <WhatsAppOutlined
                spin={spin}
                style={{
                    fontSize:
                        // eslint-disable-next-line no-nested-ternary
                        screens.xxl || screens.xl
                            ? spin
                                ? 48
                                : 32
                            : spin
                            ? 144
                            : 96,
                    color: '#fff',
                    transition: '.2s'
                }}
            />
        </a>
    )
}

export default React.memo(CallMe, () => true)
