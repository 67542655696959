import { Layout, Button } from 'antd'

import React from 'react'

import { useHistory, withRouter } from 'react-router-dom'

const { Header } = Layout

const Head = ({ location }) => {
    const history = useHistory()
    const { pathname } = location
    const [show, setShow] = React.useState(false)
    const showHandler = () => {
        if (window.scrollY > 200 || history.location.pathname !== '/')
            setShow(true)
        else setShow(false)
    }
    React.useEffect(() => {
        showHandler()
        window.onscroll = showHandler
    }, [pathname])
    return (
        <Header className="header" style={{ top: show ? 0 : -100 }}>
            <Button
                style={{
                    height: '100%',
                    padding: 0,
                    background: 'transparent',
                    border: 0
                }}
                onClick={() => history.push('/')}
            >
                <div className="logo-header" />
            </Button>

            <div className="menu-container">
                <Button
                    onClick={() => history.push('service')}
                    className={`menu-btn ${
                        pathname.includes('service') ? 'active' : ''
                    }`}
                    type="link"
                >
                    услуги
                </Button>
                <Button
                    onClick={() => history.push('contacts')}
                    className={`menu-btn ${
                        pathname.includes('contacts') ? 'active' : ''
                    }`}
                    type="link"
                >
                    контакты
                </Button>
                <Button
                    onClick={() => history.push('request')}
                    className={`menu-btn ${
                        pathname.includes('request') ? 'active' : ''
                    }`}
                    type="link"
                >
                    оставить заявку
                </Button>
            </div>
        </Header>
    )
}

export default withRouter(Head)
