import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import './styles.less'

const Header = () => {
    const history = useHistory()
    return (
        <div className="header-menu-container">
            <Button
                onClick={() => {
                    const elem = document.querySelector('.third-page')

                    window.scrollBy({
                        top: elem.offsetTop,
                        behavior: 'smooth'
                    })
                }}
                type="link"
            >
                услуги
            </Button>
            <Button onClick={() => history.push('contacts')} type="link">
                контакты
            </Button>
            <Button onClick={() => history.push('request')} type="link">
                оставить заявку
            </Button>
        </div>
    )
}

export default Header
