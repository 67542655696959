import { Button } from 'antd'
import React from 'react'
import './styles.less'
import { useHistory, withRouter } from 'react-router-dom'
import logo from '@img/logo.png'

const Header = ({ location, title }) => {
    const newTitle = Array.from(Array(20), () => `${title}_`).join('')
    const history = useHistory()
    const { pathname } = location
    const [show, setShow] = React.useState(false)
    const showHandler = () => {
        if (history.location.pathname !== '/') setShow(true)
        else setShow(false)
    }

    React.useEffect(() => {
        showHandler()
        window.onscroll = showHandler
    }, [pathname])
    return (
        <div className="header-container" style={{ top: show ? 0 : -150 }}>
            {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
            <marquee
                direction="left"
                scrollamount={10}
                className="header-running-line-container"
            >
                {newTitle}
            </marquee>
            <div className="header">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    style={{
                        height: '100%',
                        padding: 0,
                        background: 'transparent',
                        border: 0,
                        cursor: 'pointer'
                    }}
                    onClick={() => history.push('/')}
                >
                    <img className="logo-header" src={logo} alt="" />
                </div>

                <div className="menu-container">
                    <Button
                        onClick={() => history.push('/')}
                        className={`menu-btn ${
                            pathname.includes('service') ? 'active' : ''
                        }`}
                        type="link"
                    >
                        услуги
                    </Button>
                    <Button
                        onClick={() => history.push('contacts')}
                        className={`menu-btn ${
                            pathname.includes('contacts') ? 'active' : ''
                        }`}
                        type="link"
                    >
                        контакты
                    </Button>
                    <Button
                        onClick={() => history.push('request')}
                        className={`menu-btn ${
                            pathname.includes('request') ? 'active' : ''
                        }`}
                        type="link"
                    >
                        оставить заявку
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Header)
