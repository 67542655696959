import { lazy } from 'react'

const Main = lazy(() => import(`@/containers/Main`))
const Contacts = lazy(() => import(`@/containers/Contacts`))
const Service = lazy(() => import(`@/containers/Service`))
const Request = lazy(() => import(`@/containers/Request`))

export default [
    {
        name: 'main',
        path: '/',
        component: Main,
        exact: true
    },
    {
        name: 'contacts',
        path: '/contacts',
        component: Contacts,
        exact: true
    },
    {
        name: 'service',
        path: '/service',
        component: Service,
        exact: true
    },
    {
        name: 'request',
        path: '/request',
        component: Request,
        exact: true
    }
]
